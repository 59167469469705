
header {
	  background: #A2C9E4;
	  padding-top: 20px;
	  padding-bottom: 20px;
}

.inner {
	   padding: 0px 20px;
	  position: relative;
}


small {
	font-size:75%;
}
nav > ul {
  	float: left;
  	list-style-type: none;
  	margin-bottom: 0px;
}

nav > ul > li {
  	text-align: center;
	  line-height: 40px;
	  margin-left: 10px;
	  margin-right: 10px;
}

nav > ul li ul li {
	  width: 100%;
  	text-align: left;
}

nav ul li:hover {
	  cursor: pointer;
	  position: relative;
}
nav ul li:hover > ul {
  	display: block;
}
nav ul li:hover > a {
	  color: #777;
}
nav > ul > li > a {
	  cursor: pointer;
	  display: block;
  	outline: none;
  	width: 100%;
	  text-decoration: none;
}

nav > ul > li {
  	float: left;
}
nav a {
	  color: white;
}
nav > ul li ul {
  	display: none;
  	position: absolute;
  	left: 0;
	  top: 100%;
  	width: 100%;
	  z-index: 2000;
}
nav > ul li ul li > a {
	  text-decoration: none;
}

[type="checkbox"], label {
	  display: none;
}

@media screen and (max-width: 768px) {
	nav ul {
		  display: none;
	}

	label {
  		display: block;
  		background: #0191C3;
		  width: 40px;
  		height: 40px;
  		cursor: pointer;
		  position: absolute;
		  right: 20px;
  		top: 0px;
	}

label:after{
  content:'';
  display: block;
  width: 30px;
  height: 5px;
  background: #FFF;
  margin: 7px 5px;
  box-shadow: 0px 10px 0px #FFF, 0px 20px 0px #FFF
}

	[type="checkbox"]:checked ~ ul {
  		display: block;
  		z-index: 9999;
  		position: absolute;
		  right: 20px;
		  left: 20px;
	}

	nav a {
		  color: #FFFFFF;
	}

	nav ul li {
		  display: block;
  		float: none;
  		width: 100%;
  		text-align: left;
  		background: #A2C9E4;
		  text-indent: 20px;
	}

	nav > ul > li {
  		margin-left: 0px;
	}

	nav > ul li ul li {
  		display: block;
		  float: none;
	}

	nav > ul li ul {
		  display: block;
  		position: relative;
  		width: 100%;
		  z-index: 9999;
		  float: none;
	}

}


/* iPads (portrait) ----------- */
@media  screen and (max-width:1920px) {
	.box {
		width: 16%!important;
		margin: 0 0.3%!important;
	}
}

/* iPads (landscape) ----------- */
@media  screen and (max-width : 1024px)  {
	html{font-size:100%}

	.box {
		width: 32%!important;
		margin: 1% 0.6%!important;
	}
}


/* iPads (portrait and landscape) ----------- */
@media  screen and (max-width : 768px) {
	html{font-size:95%}
	.box {
		width: 32%!important;
		margin: 1% 0.6%!important;
	}

	.logo {text-align: center;}

	.topo-categoria {text-align: center;}
}

/* Smartphones (portrait and landscape) ----------- */
@media  screen and (max-width : 625px) {
	html{font-size:90%}
	.box {
		width: 48%!important;
		/*margin: 1% 1%!important;*/
	}

	.logo {font-size: 1.5em!important; margin-bottom: .5em!important;}
	.logo img {width: 25px;}

	/*.form-busca-topo input {display: none!important;}
	.form-busca-topo button {margin-top: .5em!important;}*/

	.topo-categoria {
		text-align: center;
		margin-top: .8em;
	}
}

/* Smartphones (portrait and landscape) ----------- */
@media  screen and (max-width : 480px) {
	html{font-size:90%}
	.box {
		width: 48%!important;
		/*margin: 1% 1%!important;*/
	}

	.show-small {display: block!important;}
	.show-medium-up {display: none!important;}

	.ads-significado {width: 100%; text-align: center;}
}

/* Smartphones (portrait and landscape) ----------- */
@media  screen and (max-width : 320px) {
	html{font-size:90%}

	.box {
		width: 100%!important;
		margin: 1%!important;
	}
}

article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}

h1,h2,h3,h4,h5,h6 {color: #0191C3;}

.show-small {display: none;}
.show-medium-up {display: block;}
.full-width {max-width:100%!important;width:100%!important;}
/*topo*/
.topo {
	padding: .4em 0 .7em 0;
	background: #A2C9E4;
	margin-bottom: 1em;
}

	.logo {
		margin: 0;
		line-height: normal;
		color: #FFF;
		font-size: 2em;
		text-shadow:1px 1px 0 rgba(0,0,0,0.2),2px 2px 0 rgba(0,0,0,0.2),3px 3px 0 rgba(0,0,0,0.2);
	}

		.logo a {color: #FFF;}

		/*busca do topo interna*/
		.form-busca-topo {margin: 0;}

		.form-busca-topo button {margin: .2em 0 0 0; /*background: transparent; height: 2.3125rem;*/}

		.form-busca-topo input {
			margin: .2em .5em 0 0;
			/*display: inline-block;
			width: auto;
			*/
			/*border: 1px solid #FFF;
			background: rgba(1,145,195,.5);*/
		}

			.form-busca-topo input:focus {
				/*background: rgba(1,145,195,.8);*/
				box-shadow: none;
				border-color: rgba(1,145,195,1)
			}

	.topo-busca {
    background: none repeat scroll 0 0 #fff;
    border-radius: 3px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.9), 0 0 30px rgba(0, 0, 0, 0.5);
    padding: 0.5em;
	}

	.topo-busca input.busca {
		margin: 0 0 0 0;
		/*background: #0D7ECD;*/
		/*color: #FFF;*/
	}

		.topo-busca .postfix {margin:0 0 0 0; /*background: #37C236*/}
.teste123 {

}
	.search-cover {
	    background-image: url(//significadosbr-assets.s3.amazonaws.com/img/cover-search.jpg);
	    background-position: center;
	    background-size: cover;
	    background-repeat: no-repeat;
	    display: block;
	    margin: -1em 0 0 0;
	    padding: 5em 0 ;
	}

.breadcrumbs {
	margin-bottom: 1em;
	font-size: 1.2em;
	color: #AAA;
}

	.breadcrumbs a {
		text-decoration: none;
		color: #AAA;
		font-size: .6em;
	}

		.breadcrumbs a:hover {text-decoration: underline;}


.painel-categoria {
	background: #F7F7F7;
	padding: 1.5em;
	margin-top: -1em;
}

	.topo-categoria h2 {margin: 0 0 .5em 0; color: #0191C3; line-height: normal; font-size: 1.5em;}
	.topo-categoria p {margin: 0; font-size: .850em;}

.destaques {
	padding: 2em 0;
	background: #EFEFEF;
}

.ads-significado {
	float: right;
	margin: 0 0 10px 10px;
	display: inline;
}

.ads-significado-footer {

	display: inline;
	margin-top: 20px;
}

.box {
  background: none repeat scroll 0 0 #fff;
  border-radius: 3px;
  color: #666;
  font-size: 1em;
  /*margin-bottom: 0.5em;*/
  padding: .5em;
  float: left;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
}

.menu-departamentos li ul {
  display: none;
}

	.box h2 {
		font-size: 1em;
        color: #0191C3;
		font-weight: bolder;
	}

		.box h2 a {color: #0191C3;}
		.box h2 a:hover {color: #000;}

		.box h2 img {vertical-align: middle; margin-top: .5em;}

		.box ul {list-style: none; margin: 0; padding: 0; font-size: .8em;}
		.box ul li a {color: #258faf}
		.box ul li a {margin-top: 20px;}
		.box ul li a:hover {text-decoration: underline;}

	.box-listagem {
		border-bottom: 1px solid #DDD;
		padding-bottom: .5em;
		margin: .5em 0;
	}

		.box-listagem:last-child {border-bottom: 0;}

	.box-listagem a {color: #666;}

		.box-listagem a:hover {text-decoration: underline;}

		.box-listagem h4 {
			font-size: 16px;
			margin: 0;

			font-weight: bolder;
		}

			.box-listagem h4 a {color: #0191C3;}

		.box-listagem p {
			font-size: 16px;
			margin: 0;
		}

	.box-side {
		margin: 0 0 1em 0;
		border-bottom: 2px solid #DDD;
	}

		.box-side h3 {
			font-size: 1.2em;
			font-weight: normal;
			padding: .2em .5em;
			background: #F7F7F7;
			color: #0191C3;
		}

			.box-side ul {margin: 0; padding: 0 0 .5em .5em; list-style: none;}

				.box-side ul li a {color: #258faf;}
				.box-side ul li a:hover {text-decoration: underline;}


				.box-side > ul > li {
					margin-top: 15px;
				}

.conteudo_principal a {color: #258faf; text-decoration: underline;}
.conteudo_principal a:hover {text-decoration: none;}

small a {color: #258faf; text-decoration: none!important;}
small a:hover {text-decoration: underline!important;}

.nav_rodape_significado a {color: #258faf;}
.nav_rodape_significado a:hover {text-decoration: underline;}

small a {color: #258faf;}
small a:hover {text-decoration: underline;}

	.lista-outros ul {
		list-style: none;
		margin: 0 0 1em 0;
	}

		.lista-outros ul li a {color: #258faf;}
		.lista-outros ul li a:hover {text-decoration: underline;}

.rodape {
	margin-top: 1em;
	padding: 1em 0;
	border-top: 3px solid #86B8DC;
	background: #A2C9E4;
	color: #FFF;
	text-align: center;
}
.rodape a {
	color: #FFF;
}

/* Rating */

/* 'star-rating' component */
.starRate {position:relative; margin:20px; overflow:hidden; zoom:1; }
.starRate span {font-size: 0.7em;}
.starRate ul {width:160px; margin:0; padding:0;}
.starRate li {display:inline; list-style:none;}
.starRate a, .starRate b {background:url(//s3.amazonaws.com/significadosbr-assets/img/star_rate.gif) left top repeat-x;}
.starRate a {float:right; margin:0 80px 0 -144px; width:80px; height:16px; background-position:left 16px; color:#000; text-decoration:none;}
.starRate a:hover {background-position:left -32px;}
.starRate b {position:absolute; z-index:-1; width:80px; height:16px; background-position:left -16px;}
.starRate div b {left:0px; bottom:0px; background-position:left top;}
.starRate a span {position:absolute; left:-300px;}
.starRate a:hover span {left:90px; width:100%;}



@media only screen {
	.breadcrumbs, .hide-on-mobile {
		display: none;
	}
}
@media only screen and (min-width:40.063em) {
	.breadcrumbs, .hide-on-mobile {
		display: block;
	}
}

#recommendations {
	margin: 20px 0 0 0;
}
#stickyunit {

position: fixed;

}
